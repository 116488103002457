import React from 'react'
import "./style.css"
import ys from "../../Assets/Images/ys.png";
import {useNavigate} from "react-router-dom"

const DoctorCards = ({item}) => {
    let profilePhoto = item?.photo ? item?.photo : ys
    const navigate = useNavigate()

    const handler = (route) => {
      navigate(route)
    }
  return (<>
    <div className="account-wrapper" onClick={() => {handler(`/About/${item.userId}`)}}>
    <div className="account-profile">
    <img src={profilePhoto} alt="" />
    <div className="blob-wrap">
     <div className="blob"></div>
     <div className="blob"></div>
     <div className="blob"></div>
    </div>
    <div className="account-name">{`${item?.firstName} ${item?.lastName}`}</div>
    <div className="account-title">{item.registeredAs}</div>
   </div>
   </div>
    </>)
}

export default DoctorCards