import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {baseUrl} from "../../helper"
import "./style.css"

const DoctorInfo = () => {
    const { doctorId } = useParams()
    const [selectedDoctor,setSelectedDoctor] = useState(null)
    const getAllDoctors = async () => {
      let endpoint = `${baseUrl}/users/registered/neurotherapist`
      let res = await axios.get(endpoint)
      let doctors = res.data
      let docter = doctors.filter((doc) => doc.userId === doctorId)
      setSelectedDoctor(docter[0])
    }
    useEffect(() => {
        getAllDoctors()
    }, [])
    return (<>
        {selectedDoctor &&
        <div className='doctor-profile'>
        <div className='profile-card'>
        <div className="account-profile">
        <img src={selectedDoctor?.photo} alt="" />
        <div className="account-name">{`${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`}</div>
        <div className="account-title">{selectedDoctor?.registeredAs}</div>
       </div>
       <div className='profile-details'>
       <div className='detail'>
       <div className='detail-snackbar'><span>Phone:</span><span>{selectedDoctor?.phone}</span></div>
       </div>
       <div className='detail'>
       <div className='detail-snackbar'><span>Experience:</span><span>{selectedDoctor?.experience}</span></div>
       </div>
       <div className='detail'>
       <div className='detail-snackbar'><span>Qualification:</span><span>{selectedDoctor?.qualification}</span></div>
       </div>
       <div className='detail'>
       <div className='detail-snackbar'><span>Address:</span><span>{`${selectedDoctor?.permanentAddress?.address} ${selectedDoctor?.permanentAddress?.city}`}</span></div>
       </div>
       <div className='detail'>
       <div className='detail-snackbar'><span>Certificate:</span><span style={{cursor : "pointer", fontWeight : "bolder"}} onClick={() => window.open(selectedDoctor?.certificate?.url, "_blank")}>Click here</span></div>
       </div>
       </div>
        </div>
        </div>
        }
    </>)
}

export default DoctorInfo