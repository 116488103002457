import React from 'react'
import "./style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical, faClipboardQuestion, faGraduationCap, faUserDoctor, faBook } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Services = () => {
    let services = [{ title: "Treatments", icon: faNotesMedical,path :"/Treatments" },
    { title: "Courses", icon: faGraduationCap,path :"/Courses" },
    { title: "Neurotherapists", icon: faUserDoctor,path :"/About" },
    { title: "Patient Care", icon: faClipboardQuestion,path :"/Patient" },
    { title: "Centers", icon: faNotesMedical,path :"contact" },
    { title: "Books", icon: faBook,path :"/Books" }]
    return (<>
        <div className='service-contaner'>
            <div className='heading-wrapper'>
                <h4>SERVICES</h4>
            </div>
            <h1>WHAT WE DO OFFER</h1>
            <div className='services-wrapper'>
                {services.map((service) => {
                    return <Link to={service.path}><div className='service-item'> <div className='icon'><FontAwesomeIcon icon={service.icon} /></div>
                    <h1>{service.title}</h1>
                    </div></Link>
                })}
            </div>
        </div>
    </>)
}

export default Services