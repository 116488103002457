import React from 'react'
import InfoTabBanner from '../../Components/InfoTabBanner'
import { specialChildren } from '../../Helpers/dataHelper'

const Special = () => {
  return (<>
    <InfoTabBanner info={specialChildren} />
    </>)
}

export default Special