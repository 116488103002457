import React from 'react'
import { Link } from 'react-router-dom'
import { footerDetails } from '../../Helpers/dataHelper'
import "./style.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { fa } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    const year = new Date().getFullYear()
  return (<>
    <div className='footer-container'>
    <div className='top-footer'>
    <div className='connect-footer info'>
    <h1>YS</h1>
    <p>Neurotherapy is a complete system of healing, incorporating mechanical, psychological, bio-force, and biochemical aspects. It discovers the root cause of the disease and treats the same in an integrated manner.</p>
    <div className='social-links'>
    </div>
    </div>
    <div className='additional-footer'>
    <div className='useful-links'>
    <h3>Useful Links</h3>
    <Link to={"Home"}><h6>Home</h6></Link>
    <Link to={"About"}><h6>About Us</h6></Link>
    <Link to={"About"}><h6>Doctors</h6></Link>
    <Link to={"Courses"}><h6>Courses</h6></Link>
    <Link to={"/Courses/Admission"}><h6>Admission</h6></Link>
    </div>
    <div className='useful-links'>
    <h3>Services</h3>
    <Link to={"Treatments"}><h6>Treatments</h6></Link>
    <Link to={"Courses"}><h6>Courses</h6></Link>
    <Link to={"About"}><h6>Doctors</h6></Link>
    <Link to={"Patient"}><h6>Patient Care</h6></Link>
    <Link to={"Books"}><h6>Books</h6></Link>
    </div>
    <div className='useful-links'>
    <h3>Contact Us</h3>
    <div className='contact-links'>
    <h3>Location</h3>
    <p>1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018</p>
    </div>
    <div className='contact-links'>
    <h3>Email</h3>
    <p>support@neurotherapycourse.com</p>
    </div>
    <div className='contact-links'>
    <h3>Phone</h3>
    <p>+91 9899740763</p>
    <p>+91 8368301154</p>
    </div>
    </div>
    </div>
    </div>
    <div className='footer-content'>
    <div className='rights'>
    © {year} {footerDetails?.rights}</div>
    <p>designed with ☕ By <span className="tags" onClick={() => window.open("https://www.linkedin.com/in/karandeepsingh-engineer/", "_blank")}>Karandeep Singh</span> & <span className="tags" onClick={() => window.open("https://www.linkedin.com/in/charchit-bansal/", "_blank")}>Charchit Bansal</span></p>
    </div>
    </div>
    </>)
}

export default Footer