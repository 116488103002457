import React from 'react'
import "./style.css"

const DetailCard = ({selectedCourse,windowSize}) => {
    const getTotalCourseTime = (videoList) => {
        let totalTime = 0
        videoList.map((video)=> {
            totalTime += parseInt(video?.gist?.runtime) || 0
            return null
        })
        totalTime = Math.floor((totalTime / 60) / 60)
        return totalTime
    }
  return (<>
    <div className='detail-card-container'>
    <div className="buy-content">
     <div className="buy-title">This Course Includes</div>
     <div className="buy-author"><div style={{display: "flex", flexDirection : "column"}}>
     <dic>{selectedCourse?.videoList?.length} Lectures</dic>
     <dic>{getTotalCourseTime(selectedCourse?.videoList || [])} hours on demand videos</dic>
     <dic>Certification</dic>
     </div></div>
     <div className="buy-sum">{selectedCourse?.gist?.price} ₹</div>
     <div onClick={()=> window.open("https://lms.neurotherapycourse.org/login", "_blank")} className="buy-see buy-blue">BUY</div>
     </div>
   </div>
    </>)
}

export default DetailCard