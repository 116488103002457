import React from 'react'
import AppointmentCard from './AppointmentCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardQuestion } from '@fortawesome/free-solid-svg-icons'

const Query = () => {
  return (<>
    <div className='query-container'>
    <div className='heading'>
    <FontAwesomeIcon icon={faClipboardQuestion} /><h1>Have a Query?</h1>
    </div>
    <h5>If you have any query related to our services or need quote for our services, feel free to contact us anytime on <span>+91 9899740763, +91 8368301154</span>.</h5>
    </div>
    </>)
}

export default Query