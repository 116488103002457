import { getRouteTitle } from "./commonFn";
import wellness from "../Assets/Images/wellness.png";
import treatment from "../Assets/Images/treatments.png";
import firstAid from "../Assets/Images/firstAid.png";
import sanjay from "../Assets/Images/sanjay.jpeg";
import cr1 from "../Assets/Images/cr1.jpeg";
import ys from "../Assets/Images/ys.png";
import g1 from "../Assets/Images/g1.jpeg";
import g2 from "../Assets/Images/g2.jpeg";
import g3 from "../Assets/Images/g3.jpeg";
import g4 from "../Assets/Images/g4.jpeg";
import g5 from "../Assets/Images/g5.jpeg";
import g6 from "../Assets/Images/g6.jpeg";
import g7 from "../Assets/Images/g7.jpeg";
import g8 from "../Assets/Images/g8.jpeg";
import g9 from "../Assets/Images/g9.jpeg";
import g10 from "../Assets/Images/g10.jpeg";
import g11 from "../Assets/Images/g11.jpeg";
import g12 from "../Assets/Images/g12.jpeg";
import g13 from "../Assets/Images/g13.jpeg";
import g14 from "../Assets/Images/g14.jpeg";
import g15 from "../Assets/Images/g15.jpeg";
import g16 from "../Assets/Images/g16.jpeg";
import Neurotherapy from "../Assets/Books/Neurotherapy-book-in-hindi-Final.pdf"

export let menuItems = [
  { title: "Home", isDropdwon: false },
  {
    title: "About Us",
    isDropdwon: true,
    dropItems: ["Team", "Principles", "Techniques", "Contact"]
  },
  { title: "Patient Care", isDropdwon: false },
  { title: "Treatments", isDropdwon: false },
  { title: "Courses", isDropdwon: false },
  { title: "Gallery", isDropdwon: false },
  { title: "Events", isDropdwon: false },
  { title: "Books", isDropdwon: false }
];
export const CarouselImages = [wellness, treatment];
export const CarouselImages2 = [cr1];
export const booksData = [{
   title : 'Neurotherapy-book-in-hindi-Final',
   routeTitle: getRouteTitle("Diploma Course (1 year)"),
   url:
     "https://resources.reed.co.uk/courses/coursemedia/343869/88a3ce6c-fc25-4a00-ab49-ba4742f2f02f_cover.jpg",
     book : Neurotherapy
}]
export const rateListArray = [
  {
    heading: "Lipid Profile",
    headingPrice: "300₹",
    subTreatments: [
      {
        title: "Cholestrol",
        price: "80₹"
      },
      {
        title: "Trigly",
        price: "100₹"
      },
      {
        title: "HDL",
        price: "80₹"
      },
      {
        title: "LDL",
        price: "100₹"
      },
      {
        title: "VLDL",
        price: "100₹"
      }
    ]
  },
  {
    heading: "LFT",
    headingPrice: "300₹",
    subTreatments: [
      {
        title: "Bilirubin",
        price: "50₹"
      },
      {
        title: "S.G.O.T",
        price: "50₹"
      },
      {
        title: "S.G.P.T",
        price: "50₹"
      },
      {
        title: "Total Protien",
        price: "50₹"
      },
      {
        title: "Albumin",
        price: "50₹"
      },
      {
        title: "Globulin",
        price: "50₹"
      },
      {
        title: "Phosphate",
        price: "50₹"
      },
      {
        title: "TB",
        price: "50₹"
      },
      {
        title: "DB",
        price: "50₹"
      },
      {
        title: "IB",
        price: "50₹"
      }
    ]
  },
  {
    heading: "KFT",
    headingPrice: "300₹",
    subTreatments: [
      {
        title: "Urea",
        price: "60₹"
      },
      {
        title: "Cretinine",
        price: "60₹"
      },
      {
        title: "Uric Acid",
        price: "60₹"
      },
      {
        title: "Calcium",
        price: "60₹"
      },
      {
        title: "Phosphorous",
        price: "60₹"
      },
      {
        title: "Protien",
        price: "60₹"
      },
      {
        title: "Sodium",
        price: "100₹"
      },
      {
        title: "Potassium",
        price: "100₹"
      }
    ]
  },
  {
    heading: "CBC",
    headingPrice: "180₹",
    subTreatments: [
      {
        title: "HB",
        price: "30₹"
      },
      {
        title: "TLC",
        price: "30₹"
      },
      {
        title: "DLC",
        price: "30₹"
      },
      {
        title: "ESR",
        price: "30₹"
      },
      {
        title: "TEC",
        price: "30₹"
      },
      {
        title: "PCY",
        price: "30₹"
      },
      {
        title: "P.Count",
        price: "50₹"
      },
      {
        title: "M.C.V.",
        price: "50₹"
      },
      {
        title: "M.C.H.",
        price: "50₹"
      },
      {
        title: "M.C.H.C.",
        price: "50₹"
      },
      {
        title: "Dengu NS-1",
        price: "500₹"
      },
      {
        title: "Dengu 1gm/1gg",
        price: "600₹"
      },
      {
        title: "Chicken Gunia",
        price: "600₹"
      }
    ]
  },
  {
    heading: "Thyroid",
    headingPrice: "-",
    subTreatments: [
      {
        title: "T3 T4 TSH",
        price: "350₹"
      },
      {
        title: "FT3 FT4 TSH",
        price: "550₹"
      },
      {
        title: "TSH",
        price: "200₹"
      },
      {
        title: "Instant Sugar",
        price: "30₹"
      },
      {
        title: "Sugar (F)",
        price: "30₹"
      },
      {
        title: "Sugar (PP)",
        price: "30₹"
      },
      {
        title: "HBA 1C",
        price: "300₹"
      },
      {
        title: "Urine Sugar",
        price: "30₹"
      },
      {
        title: "Urine R/M ME",
        price: "50₹"
      },
      {
        title: "Urine micro Abumin",
        price: "100₹"
      },
      {
        title: "Stool R/M",
        price: "30₹"
      },
      {
        title: "S.Amylse",
        price: "200₹"
      },
      {
        title: "Vitamin D3",
        price: "600₹"
      },
      {
        title: "Vitamin B12",
        price: "450₹"
      },
      {
        title: "VDRL",
        price: "50₹"
      },
      {
        title: "Widal Test",
        price: "60₹"
      },
      {
        title: "MP",
        price: "30₹"
      },
      {
        title: "CRP Quantatitve",
        price: "250₹"
      },
      {
        title: "RA Factor Quantitiave",
        price: "250₹"
      },
      {
        title: "BT",
        price: "30₹"
      },
      {
        title: "CT",
        price: "30₹"
      },
      {
        title: "P/S",
        price: "30₹"
      },
      {
        title: "Montox Test",
        price: "70₹"
      },
      {
        title: "AFB Stain",
        price: "50₹"
      },
      {
        title: "Semen Analysis",
        price: "50₹"
      },
      {
        title: "Typhoidal 1gm/1gg",
        price: "300₹"
      },
      {
        title: "E-2",
        price: "150₹"
      },
      {
        title: "Iron Profile",
        price: "400₹"
      },
      {
        title: "Prolactine",
        price: "300₹"
      },
      {
        title: "Anti CCP",
        price: "800₹"
      },
      {
        title: "PSA",
        price: "300₹"
      },
      {
        title: "BHCG",
        price: "400₹"
      },
      {
        title: "FT3",
        price: "250₹"
      },
      {
        title: "FT4",
        price: "250₹"
      },
      {
        title: "FSH",
        price: "250₹"
      },
      {
        title: "Testosterone",
        price: "300₹"
      },
      {
        title: "LH",
        price: "250₹"
      },
      {
        title: "Insulin F",
        price: "300₹"
      },
      {
        title: "Insulin PP",
        price: "300₹"
      },
      {
        title: "Maleria Anitgen",
        price: "300₹"
      },
      {
        title: "A.S.O.Titre",
        price: "300₹"
      },
      {
        title: "Blood Group",
        price: "30₹"
      },
      {
        title: "Blood Culture",
        price: "500₹"
      },
      {
        title: "Alkaline",
        price: "60₹"
      }
    ]
  }
];

export const topicCardsData = [
  {
    title: "Rate List",
    url:
      "https://www.supremehealthclinic.com/wp-content/uploads/2021/07/neurotherapy-1.jpeg",
    poition: "Architect & Engineer",
    routeTitle: getRouteTitle("Rate List")
  },
  {
    title: "Treatments",
    url:
      "https://www.supremehealthclinic.com/wp-content/uploads/2021/07/neurotherapy-1.jpeg",
    poition: "Architect & Engineer",
    routeTitle: getRouteTitle("Treatments")
  },
  {
    title: "Courses",
    url:
      "https://www.supremehealthclinic.com/wp-content/uploads/2021/07/neurotherapy-1.jpeg",
    poition: "Architect & Engineer",
    routeTitle: getRouteTitle("Courses")
  },
  {
    title: "Gallery",
    url:
      "https://www.supremehealthclinic.com/wp-content/uploads/2021/07/neurotherapy-1.jpeg",
    poition: "Architect & Engineer",
    routeTitle: getRouteTitle("Gallery")
  }
];

export const infoTabBanner1 = {
  title: "Senior Neurotherapist Sanjay Yadav",
  description: `Mr. Sanjay Yadav, of Director of Ys Neurotherapy Health and Research Foundation,and Member of the Dr. Lajpath Rai Mehra Neurotherapy, a qualified Neurotherapist, from Mumbai India, worked with the pioneer Mr. Lajpatrai Mehra as a consultant, lecturer and researcher in the field of Neurotherapy.
  He has organized countless seminars, workshops & camps like Yoga& Meditation, Naturopathy Education Camp, Body Balancing Workshop at Corporate, Institutions, Schools & College Levels
  Our team of highly skilled individuals, dedicated to creating a better approach to healing. Our mission is to provide the most advanced and comprehensive approach to healing available. Our entire staff is committed to personal.`,
  url: sanjay
};
export const infoTabBanner2 = {
  title: "who are eligible for Neurotherapy",
  description:
    "Neurotherapy can be particularly useful for individuals who have not found relief from traditional forms of treatment. It is non-invasive, medication-free, and has no known negative side effects, making it an attractive option for many people. Additionally, neurotherapy can help individuals develop greater self-awareness and self-regulation skills, leading to improved mood, focus, and overall well-being. By targeting specific areas of the brain that are associated with certain symptoms or conditions, neurotherapy can help individuals overcome challenges and achieve their goals. With continued practice, the benefits of neurotherapy can be long-lasting, improving quality of life for individuals and their loved ones.",
  url: firstAid
};

export const specialChildren = {
  title: "Specialized Treatment For Special Children",
  description: `Specialized treatment for special children refers to medical and therapeutic interventions designed specifically for children with developmental, cognitive, or physical disabilities. These treatments are tailored to the unique needs of each child and aim to help them reach their full potential.

  Specialized treatment for special children may include therapies such as occupational therapy, speech therapy, and physical therapy to address specific challenges related to motor skills, communication, and sensory processing. Behavioral therapy and counseling may also be used to address emotional and behavioral challenges that can arise from living with a disability.
  
  In addition to therapy, specialized treatment for special children may include medical interventions such as medication to manage symptoms of conditions like ADHD or autism, as well as surgeries to correct physical abnormalities. Overall, the goal of specialized treatment for special children is to help them achieve their fullest potential and lead fulfilling lives.`,
  url:
    "https://www.nichq.org/sites/default/files/styles/custom_square/public/text-and-image-side-by-side-image/boy%20and%20doc-web_0.jpg?itok=MAyeXwFh"
};
export const patientPage = {
  title: "Learning Neurotherapy For Patients..",
  description: `Attention please...

  Patient can take neurotherapy treatment by his family member after making him learn or he can take by the neurotherapy expert also. For further enquiry about the cost you can contact your neurotherapy expert.`,
  url:
    "https://www.nichq.org/sites/default/files/styles/custom_square/public/text-and-image-side-by-side-image/boy%20and%20doc-web_0.jpg?itok=MAyeXwFh"
};
export const events = [
  {
    date: "27/08/2023",
    eventName: "NEUROTHERAPY WORKSHOP",
    fee: "FREE",
    place: "1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018",
    registration: "On Spot",
    time: "08:00 a.m. - 12:30 p.m.",
    routeTitle: getRouteTitle("NEUROTHERAPY WORKSHOP")
  },
  {
    date: "20/08/2023",
    eventName: "NEUROTHERAPY WORKSHOP",
    fee: "FREE",
    place: "1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018",
    registration: "On Spot",
    time: "08:00 a.m. - 02:00 p.m.",
    routeTitle: getRouteTitle("NEUROTHERAPY WORKSHOP")
  },
  {
    date: "13/08/2023",
    eventName: "NEUROTHERAPY WORKSHOP",
    fee: "FREE",
    place: "1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018",
    registration: "On Spot",
    time: "08:00 a.m. - 02:00 p.m.",
    routeTitle: getRouteTitle("NEUROTHERAPY WORKSHOP")
  },
  {
    date: "17/04/2023",
    eventName: "NEUROTHERAPY WORKSHOP",
    fee: "FREE",
    place: "1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018",
    registration: "On Spot",
    time: "10:00 a.m. - 6:00 p.m.",
    routeTitle: getRouteTitle("NEUROTHERAPY WORKSHOP")
  }
];

export const videoData = [
  `<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FYSNEUROTHERAPYHELTHRESEARCHFOUNDATION%2Fvideos%2F2118857651642412%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`,
  `<iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2FYSNEUROTHERAPYHELTHRESEARCHFOUNDATION%2Fvideos%2F979253369580655%2F&show_text=false&width=560&t=0" width="560" height="308" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`,
  `<iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2FYSNEUROTHERAPYHELTHRESEARCHFOUNDATION%2Fvideos%2F353503693072244%2F&show_text=false&width=560&t=0" width="560" height="308" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`,
  `<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FYSNEUROTHERAPYHELTHRESEARCHFOUNDATION%2Fvideos%2F2515003635439350%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`
];
export const galleryData = [
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g1,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g2,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g3,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g4,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g5,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g6,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g7,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g8,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g9,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g10,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g11,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g12,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g13,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g14,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g15,
    type: "item--medium"
  },
  {
    id: 3,
    title: "YS",
    label: "It is a long established fact that a reader",
    url: g16,
    type: "item--medium"
  }
];
export const coursesData = [
  {
    id: 1,
    title: "Diploma Course (1 year)",
    routeTitle: getRouteTitle("Diploma Course (1 year)"),
    description: "10 + 2 for Neurotherapist",
    url:
      "https://resources.reed.co.uk/courses/coursemedia/343869/88a3ce6c-fc25-4a00-ab49-ba4742f2f02f_cover.jpg"
  },
  {
    id: 2,
    title: "Basic Course (6 months)",
    routeTitle: getRouteTitle("Basic Course (6 months)"),
    description: "10th pass for Therapist",
    url:
      "https://resources.reed.co.uk/courses/coursemedia/343869/88a3ce6c-fc25-4a00-ab49-ba4742f2f02f_cover.jpg"
  }
];

export const sanjayYadav = {
  title: "Sanjay Yadav",
  url: sanjay,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Sanjay Yadav"),
  description: `Mr. Sanjay Yadav, of Director of Ys Neurotherapy Health and Research Foundation,and Member of the Dr. Lajpath Rai Mehra Neurotherapy, a qualified Neurotherapist, from Mumbai India, worked with the pioneer Mr. Lajpatrai Mehra as a consultant, lecturer and researcher in the field of Neurotherapy.
   He has organized countless seminars, workshops & camps like Yoga& Meditation, Naturopathy Education Camp, Body Balancing Workshop at Corporate, Institutions, Schools & College Levels
   Our team of highly skilled individuals, dedicated to creating a better approach to healing. Our mission is to provide the most advanced and comprehensive approach to healing available. Our entire staff is committed to personal.`
};
export const semma = {
  title: "Dr Semma Puri",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Semma Puri"),
  description: ``
};
export const Kulwinder = {
  title: "Dr Kulwinder Singh",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Jaspreet Kaur"),
  description: ``
};
export const jaspreet = {
  title: "Dr Jaspreet Kaur",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Jaspreet Kaur"),
  description: ``
};
export const manpreet = {
  title: "Dr Mapreet Kaur",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Mapreet Kaur"),
  description: ``
};
export const anshikha = {
  title: "Dr Anshikha",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Anshikha"),
  description: ``
};
export const bhavika = {
  title: "Dr Bhavika",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Bhavika"),
  description: ``
};
export const surbhi = {
  title: "Dr Surbhi Mishra",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr SurbhiMishra"),
  description: ``
};
export const vishakha = {
  title: "Dr Vishakha Bogra",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Vishakha Bogra"),
  description: ``
};
export const disha = {
  title: "Dr Disha Malhotra",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Disha Malhotra"),
  description: ``
};
export const raman = {
  title: "Dr Ramandeep Kaur",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Ramandeep Kaur"),
  description: ``
};
export const ahuja = {
  title: "Dr Ahuja",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("Dr Ahuja"),
  description: ``
};
export const harpal = {
  title: "S Harpal Singh",
  url: ys,
  position: "Neurotherapist",
  routeTitle: getRouteTitle("S Harpal Singh"),
  description: ``
};
export const allDoctors = [
  sanjayYadav,
  semma,
  Kulwinder,
  jaspreet,
  manpreet,
  anshikha,
  bhavika,
  surbhi,
  vishakha,
  disha,
  raman,
  ahuja,
  harpal
];
export const footerDetails = {
  rights: "Neurotherapy Healthcare. All Rights Reserved",
  // designed: "designed with ☕ By <span>Karandeep Singh</span> & <span>Charchit Bansal</span>"
};

export const treatments = [
  {
    title: "ADD Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("ADD Treatment"),
    description: `Attention deficit disorder (ADD) is a subtype of ADHD that is characterized by symptoms of inattention without hyperactivity. Like ADHD, ADD is typically treated through a combination of medication and behavioral therapy. Medications such as stimulants and non-stimulants can be effective in reducing symptoms of inattention, improving focus and concentration. Behavioral therapy may involve strategies such as cognitive-behavioral therapy, organization skills training, or time management techniques. It is important for individuals with ADD to work with a healthcare professional to determine the most effective treatment plan for their unique needs, as well as any potential comorbid conditions that may impact treatment outcomes.`
  },
  {
    title: "ADHD Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("ADHD Treatment"),
    description: `Attention-deficit/hyperactivity disorder (ADHD) is typically treated through a combination of medication and behavioral therapy. Medications such as stimulants and non-stimulants are often used to reduce symptoms of inattention, hyperactivity, and impulsivity. Behavioral therapy may involve strategies such as cognitive-behavioral therapy, parent training, or social skills training. It is important for individuals with ADHD to work with a healthcare professional to determine the most effective treatment plan for their unique needs.`
  },
  {
    title: "BP Problem Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("BP Problem Treatment"),
    description: `High blood pressure (BP) is a common condition that can increase the risk of heart disease, stroke, and other health problems. The treatment for high BP typically involves lifestyle changes and medication. Lifestyle changes may include maintaining a healthy diet, engaging in regular physical activity, reducing alcohol consumption, quitting smoking, and managing stress. Medications may include diuretics, ACE inhibitors, calcium channel blockers, beta-blockers, or a combination of these drugs. It is important for individuals with high BP to work with a healthcare professional to determine the most appropriate treatment plan for their individual needs, monitor their BP regularly, and make any necessary adjustments to their treatment plan over time.`
  },
  {
    title: "Ankylosing Spondylitis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Ankylosing Spondylitis Treatment"),
    description: `Ankylosing spondylitis (AS) is a chronic inflammatory condition that primarily affects the spine, but can also impact other joints and organs. The treatment for AS typically involves a combination of medication and physical therapy. Medications may include nonsteroidal anti-inflammatory drugs (NSAIDs), disease-modifying antirheumatic drugs (DMARDs), or biologic medications that target specific proteins involved in the inflammatory process. Physical therapy may involve exercises to improve posture, flexibility, and strength, as well as breathing techniques to manage chest expansion limitations. In severe cases, surgery may be recommended to address joint damage or spinal deformities. It is important for individuals with AS to work with a healthcare professional to develop an individualized treatment plan that addresses their unique symptoms and needs.`
  },
  {
    title: "Anxiety Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("AAnxiety Treatment"),
    description: `Anxiety disorders are a group of mental health conditions that involve excessive worry, fear, and anxiety. Treatment for anxiety disorders typically involves a combination of therapy and medication.

      Cognitive-behavioral therapy (CBT) is a commonly used therapy that can help individuals learn to manage their anxiety symptoms by identifying and changing negative patterns of thought and behavior. Other types of therapy, such as exposure therapy and mindfulness-based therapies, may also be helpful.
      
      Medications used to treat anxiety disorders include antidepressants, benzodiazepines, and beta-blockers. These medications can help to reduce symptoms of anxiety, but it is important to work with a healthcare professional to determine the most appropriate medication and dosage.
      
      Lifestyle changes, such as regular exercise, stress management techniques, and avoiding or limiting alcohol and caffeine intake, can also be helpful in managing anxiety symptoms.
      
      It is important for individuals with anxiety disorders to work with a healthcare professional to determine the most effective treatment plan for their unique needs. Treatment may involve a combination of therapy, medication, and lifestyle changes, and may need to be adjusted over time to address changes in symptoms and circumstances.`
  },
  {
    title: "Autism Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Autism Treatment"),
    description: `Autism spectrum disorder (ASD) is a neurodevelopmental disorder that affects communication, social interaction, and behavior. While there is no cure for ASD, early intervention and treatment can greatly improve outcomes for individuals with ASD.

      Behavioral and communication therapies, such as applied behavior analysis (ABA), speech therapy, and social skills training, are often used to help individuals with ASD learn and practice skills that can improve communication, socialization, and behavior. Occupational therapy may also be helpful in developing skills related to daily living activities.
      
      Medications may be used to address specific symptoms of ASD, such as anxiety, hyperactivity, or aggression. However, medication is not a primary treatment for ASD and should be used in conjunction with therapy.
      
      `
  },
  {
    title: "Any type of spine problem Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("ADD Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Arthritis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Arthritis Treatment"),
    description: `Arthritis is a condition that causes joint pain and stiffness. Treatment for arthritis typically involves a combination of medication, exercise, and lifestyle changes.

      Medications used to treat arthritis may include nonsteroidal anti-inflammatory drugs (NSAIDs), corticosteroids, or disease-modifying antirheumatic drugs (DMARDs). Exercise, such as stretching, range-of-motion exercises, and low-impact activities like swimming or cycling, can help to reduce pain and stiffness and improve joint function.
      
      Lifestyle changes, such as maintaining a healthy weight, eating a balanced diet, and avoiding or limiting alcohol and tobacco use, can also be helpful in managing arthritis symptoms. In some cases, surgery may be recommended to repair or replace damaged joints.`
  },
  {
    title: "Asthma Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Asthma Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Bronchitis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Bronchitis Treatment"),
    description: `Bronchitis is an inflammation of the lining of the bronchial tubes, which carry air to and from the lungs. The treatment for bronchitis typically depends on whether it is acute or chronic.

      Acute bronchitis, which is caused by a viral infection, usually resolves on its own within a few weeks. Treatment may involve rest, hydration, and over-the-counter medications such as acetaminophen or ibuprofen to relieve symptoms of pain and fever. Cough suppressants may also be used to manage coughing.
      
      Chronic bronchitis, which is a type of chronic obstructive pulmonary disease (COPD), requires ongoing management to prevent and manage symptoms. Treatment may involve bronchodilators to open up the airways, inhaled steroids to reduce inflammation, and oxygen therapy to improve breathing. Lifestyle changes, such as quitting smoking and avoiding triggers such as air pollution or irritants, can also be helpful in managing symptoms.`
  },
  {
    title: "Cerbral palsi Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("ACerbral palsi Treatment"),
    description: `Cerebral palsy (CP) is a group of disorders that affect movement and posture due to damage to the developing brain. Treatment for CP is focused on managing symptoms and improving function.

      Physical therapy is often a cornerstone of CP treatment, helping individuals with CP to improve mobility, strength, and range of motion. Occupational therapy may also be helpful in developing skills related to daily living activities.
      
      Medications may be used to manage spasticity, seizures, and other symptoms of CP. In some cases, surgery may be recommended to address joint or muscle contractures or other structural issues.
      
      Assistive devices such as braces, splints, or walkers may also be helpful in improving mobility and function. Speech therapy may also be necessary for individuals with CP who have difficulty with speech or communication.`
  },
  {
    title: "Calf muscle pain Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Calf muscle pain Treatment"),
    description: `Calf muscle pain can be caused by a variety of factors, such as overuse, injury, or underlying medical conditions. Treatment for calf muscle pain depends on the underlying cause and severity of the pain.

      For mild to moderate calf muscle pain, rest and gentle stretching can often help to alleviate symptoms. Applying ice or heat to the affected area can also be helpful in reducing pain and inflammation.
      
      Over-the-counter pain medications, such as acetaminophen or ibuprofen, may be used to manage pain and reduce inflammation. For more severe or persistent calf muscle pain, physical therapy may be recommended to improve mobility and strength.`
  },
  {
    title: "Survical spondyloses Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Survical spondyloses Treatment"),
    description: `Cervical spondylosis, also known as neck arthritis, is a condition that causes wear and tear on the cartilage and bones of the neck. Treatment for cervical spondylosis is typically aimed at managing symptoms and preventing further damage to the spine.

      For mild to moderate cervical spondylosis, rest, gentle stretching, and over-the-counter pain medications such as acetaminophen or ibuprofen may be helpful in relieving symptoms. Applying heat or cold to the affected area can also be helpful in reducing pain and inflammation.
      
      Physical therapy may be recommended to improve mobility, flexibility, and strength in the neck and shoulders. In some cases, a cervical collar or neck brace may be used to provide support and reduce pain.`
  },
  {
    title: "Depression Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Depression Treatment"),
    description: `Depression is a mood disorder that can be treated with a variety of approaches, depending on the severity of symptoms and the individual's preferences and circumstances.

      Psychotherapy, also known as talk therapy, is a common treatment for depression. This type of therapy involves talking with a mental health professional to identify negative thought patterns and behaviors, and to develop coping strategies and problem-solving skills.
      
      Antidepressant medications can also be helpful in treating depression by restoring balance to certain chemicals in the brain that affect mood. There are several classes of antidepressants, including selective serotonin reuptake inhibitors (SSRIs) and serotonin and norepinephrine reuptake inhibitors (SNRIs).`
  },
  {
    title: "Diabetes Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Diabetes Treatment"),
    description: `Diabetes is a chronic condition that affects how the body processes glucose, a type of sugar that serves as the body's main source of energy. Treatment for diabetes is aimed at managing blood sugar levels and preventing complications associated with the condition.

      For individuals with type 1 diabetes, treatment involves daily insulin injections or the use of an insulin pump to deliver insulin to the body. For individuals with type 2 diabetes, treatment may involve lifestyle changes such as weight loss, a healthy diet, and regular exercise, as well as medications such as oral hypoglycemic agents or injectable insulin.`
  },
  {
    title: "Digestive disorder Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Digestive disorder Treatment"),
    description: `Digestive disorders are a broad category of conditions that affect the digestive tract, including the esophagus, stomach, intestines, liver, pancreas, and gallbladder. Treatment for digestive disorders depends on the specific condition and the severity of symptoms.

      For mild digestive symptoms, lifestyle changes such as a healthy diet, regular exercise, and stress reduction techniques may be helpful in managing symptoms. Over-the-counter medications such as antacids or laxatives may also be used to alleviate symptoms.
      
      For more severe or persistent digestive symptoms, prescription medications such as antibiotics, proton pump inhibitors, or anti-inflammatory drugs may be necessary. In some cases, surgery may be recommended to address structural issues or complications associated with a digestive disorder.`
  },
  {
    title: "Down syndrome Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Down syndrome Treatment"),
    description: `Down syndrome is a genetic disorder caused by the presence of an extra copy of chromosome 21. While there is no cure for Down syndrome, treatment focuses on managing symptoms and improving quality of life.

      Early intervention programs may be recommended to help children with Down syndrome develop cognitive, language, and social skills. This may include speech therapy, physical therapy, and occupational therapy. Special education programs can also help children with Down syndrome succeed academically.
      
      `
  },
  {
    title: "Fits Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Fits Treatment"),
    description: `Fits, also known as seizures, can be caused by a variety of factors such as epilepsy, head injury, fever, infection, or stroke. The treatment for fits depends on the underlying cause and the severity of the seizures.

      If an individual experiences a seizure for the first time, it is important to seek medical attention immediately. In some cases, medication may be prescribed to manage seizures, and lifestyle changes such as getting enough sleep and avoiding triggers may be recommended.`
  },
  {
    title: "Frozen shoulder Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Frozen shoulder Treatment"),
    description: `Frozen shoulder, also known as adhesive capsulitis, is a condition that causes stiffness and pain in the shoulder joint. Treatment for frozen shoulder usually involves a combination of physical therapy, medication, and occasionally, surgery.

      Physical therapy exercises can help improve range of motion and reduce pain in the shoulder joint. A healthcare professional may also recommend stretching exercises or other techniques to help loosen the shoulder muscles.`
  },
  {
    title: "Hypoxia Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Hypoxia Treatment"),
    description: `Hypoxia is a condition in which the body does not receive enough oxygen. Treatment for hypoxia depends on the underlying cause and the severity of the condition.

      In cases of mild hypoxia, increasing the amount of oxygen in the air by using an oxygen mask or other breathing device may be sufficient. In more severe cases, hospitalization and supplemental oxygen therapy may be necessary.
      
      In addition to oxygen therapy, treatment may also involve addressing the underlying cause of the hypoxia. For example, if hypoxia is caused by a respiratory infection or asthma, medications may be prescribed to treat these conditions. If hypoxia is caused by high altitude, descending to a lower altitude may be necessary.`
  },
  {
    title: "Hormonal imbalance Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Hormonal imbalance Treatment"),
    description: `Treatment for hormonal imbalances depends on the specific hormone that is affected and the underlying cause of the imbalance.

      For example, if a person has low levels of thyroid hormone, hormone replacement therapy may be prescribed. If a person has high levels of insulin, medication may be prescribed to regulate blood sugar levels.
      
      Lifestyle changes such as a healthy diet and regular exercise can also help improve hormonal imbalances, particularly those related to insulin resistance, weight gain, or stress.`
  },
  {
    title: "Insomnia Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Insomnia Treatment"),
    description: `Insomnia is a sleep disorder characterized by difficulty falling asleep or staying asleep. Treatment for insomnia may involve a combination of lifestyle changes, therapy, and medication.

      Lifestyle changes that can improve insomnia include establishing a regular sleep routine, avoiding stimulating activities before bedtime, and avoiding caffeine and alcohol. Cognitive behavioral therapy (CBT) may also be recommended to address underlying psychological factors contributing to insomnia.
      
      Medications for insomnia may include over-the-counter sleep aids, prescription medications such as sedatives or antidepressants, or melatonin supplements. It is important to talk to a healthcare professional before starting any sleep medications, as they can have side effects and can be habit-forming if not used correctly.
      
      In some cases, treating underlying medical conditions or addressing sleep apnea or restless leg syndrome may also improve insomnia.`
  },
  {
    title: "Kidney problem Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Kidney problem Treatment"),
    description: ``
  },
  {
    title: "Insomnia Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Insomnia Treatment"),
    description: `Insomnia is a sleep disorder characterized by difficulty falling asleep or staying asleep. Treatment for insomnia may involve a combination of lifestyle changes, therapy, and medication.

      Lifestyle changes that can improve insomnia include establishing a regular sleep routine, avoiding stimulating activities before bedtime, and avoiding caffeine and alcohol. Cognitive behavioral therapy (CBT) may also be recommended to address underlying psychological factors contributing to insomnia.
      
      Medications for insomnia may include over-the-counter sleep aids, prescription medications such as sedatives or antidepressants, or melatonin supplements. It is important to talk to a healthcare professional before starting any sleep medications, as they can have side effects and can be habit-forming if not used correctly.
      
      In some cases, treating underlying medical conditions or addressing sleep apnea or restless leg syndrome may also improve insomnia.`
  },
  {
    title: "Obesity Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Obesity Treatment"),
    description: `The treatment for obesity typically involves a combination of lifestyle changes, diet, exercise, and sometimes medication or surgery.

      Lifestyle changes that can help with weight loss and management include increasing physical activity, reducing calorie intake, and avoiding sugary or high-fat foods. Behavioral therapy, such as cognitive-behavioral therapy or motivational interviewing, may also be recommended to help individuals make sustainable changes to their lifestyle.
      
      Medications may be prescribed in some cases to help with weight loss, particularly for individuals who have not seen results from lifestyle changes alone. However, medication should only be used under the guidance of a healthcare professional, as they can have side effects.`
  },
  {
    title: "Ovarian Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Ovarian Treatment"),
    description: `The treatment for ovarian problems depends on the specific condition affecting the ovaries. Some common ovarian conditions include ovarian cysts, polycystic ovary syndrome (PCOS), and ovarian cancer.

      Treatment for ovarian cysts depends on the size and type of the cyst. Small, functional cysts may go away on their own, while larger cysts may require surgical removal.
      
      PCOS is a hormonal disorder that can cause irregular periods and other symptoms. Treatment may involve medication to regulate hormones and manage symptoms, as well as lifestyle changes such as a healthy diet and regular exercise.`
  },
  {
    title: "Migrane Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Migrane Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Memory problems Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Memory problems Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Mental retardation Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Mental retardation Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Motor Neuron Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Motor Neuron Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Multiple Sclerosis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Multiple Sclerosis Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Muscular distrophy Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Muscular distrophy Treatment"),
    description: `Muscular dystrophy is a group of genetic disorders that cause progressive muscle weakness and degeneration. There is no known cure for muscular dystrophy, and treatment aims to manage symptoms and improve quality of life.

      Depending on the type of muscular dystrophy, treatment may include physical therapy, occupational therapy, and orthopedic devices such as braces or wheelchairs to help with mobility and prevent complications such as scoliosis.
      
      Medications such as corticosteroids or immunosuppressants may be prescribed to slow the progression of the disease and improve muscle strength. In some cases, gene therapy or stem cell therapy may be considered as experimental treatments.
      
      `
  },
  {
    title: "Naval Problem Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Naval Problem Treatment"),
    description: ``
  },
  {
    title: "Paralysis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Paralysis Treatment"),
    description: `The treatment of paralysis depends on the underlying cause and the extent of the paralysis. For temporary paralysis caused by a reversible condition such as a pinched nerve or a stroke, treatment may include medications, physical therapy, or surgery to alleviate the underlying problem and help the patient regain function.

      For permanent paralysis caused by a spinal cord injury, traumatic brain injury, or other irreversible condition, treatment focuses on managing symptoms and improving quality of life. This may include rehabilitation, assistive devices such as braces or wheelchairs, and modifications to the patient's environment to improve accessibility and safety.
      
      In some cases, experimental treatments such as stem cell therapy or nerve grafts may be considered to help restore function. However, these treatments are still in the early stages of research and are not widely available.`
  },
  {
    title: "Parkinson Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Parkinson Treatment"),
    description: `Parkinson's disease is a neurodegenerative disorder that affects movement, causing tremors, stiffness, and difficulty with balance and coordination. Treatment for Parkinson's disease aims to alleviate symptoms, slow the progression of the disease, and improve quality of life.

      For mild to moderate Parkinson's disease, medications such as levodopa, dopamine agonists, or MAO-B inhibitors may be prescribed to increase dopamine levels in the brain and reduce symptoms. These medications are typically prescribed by a neurologist who specializes in movement disorders.`
  },
  {
    title: "Prolapse of uterus Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Prolapse of uterus Treatment"),
    description: `Psoriasis is a chronic autoimmune condition that causes skin cells to build up rapidly, resulting in thick, scaly patches on the skin. Treatment for psoriasis aims to alleviate symptoms, reduce inflammation, and prevent complications.

      For mild to moderate psoriasis, topical treatments such as corticosteroids, vitamin D analogues, or coal tar preparations may be helpful in reducing inflammation and slowing the growth of skin cells. Light therapy or phototherapy, which involves exposing the skin to ultraviolet light, may also be used to reduce symptoms.
      
      For more severe psoriasis, systemic medications such as methotrexate, cyclosporine, or biologic agents may be necessary to reduce inflammation and slow the growth of skin cells. These medications are typically prescribed by a dermatologist or rheumatologist.`
  },
  {
    title: "Rheumatoid Arthritis (RA) Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Rheumatoid Arthritis (RA) Treatment"),
    description: `Rheumatoid arthritis (RA) is a chronic autoimmune disorder that affects the joints, causing pain, swelling, and stiffness. Treatment for RA aims to reduce inflammation, prevent joint damage, and improve quality of life.

      For mild to moderate RA, nonsteroidal anti-inflammatory drugs (NSAIDs) or corticosteroids may be prescribed to reduce pain and inflammation. Disease-modifying antirheumatic drugs (DMARDs) such as methotrexate, sulfasalazine, or hydroxychloroquine may also be used to slow the progression of the disease and prevent joint damage.
      
      In more severe cases, biologic agents such as tumor necrosis factor (TNF) inhibitors, interleukin (IL) inhibitors, or Janus kinase (JAK) inhibitors may be prescribed to reduce inflammation and slow the progression of the disease.`
  },
  {
    title: "Psoriasis Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle(" Psoriasis Treatmentent"),
    description: `Sciatica pain is caused by compression or irritation of the sciatic nerve, which runs from the lower back down the back of each leg. Treatment for sciatica pain is aimed at reducing pain and inflammation and improving mobility.

      For mild sciatica pain, rest, heat or ice therapy, and over-the-counter pain relievers such as ibuprofen or acetaminophen may be helpful in reducing symptoms. Gentle stretching exercises or physical therapy may also be recommended to improve flexibility and reduce the risk of future flare-ups.
      
      In more severe cases, prescription medications such as muscle relaxants or opioids may be necessary to manage pain. Injections of corticosteroids or other medications may also be used to reduce inflammation and alleviate symptoms.`
  },
  {
    title: "Scaitica pain Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Scaitica pain Treatment"),
    description: `Hypothyroid symptom is a major setback of the thyroid gland in our body. It can be termed as one of the worst diseases can occur in an individual. Hypothyroid is hereditarily transferable disease and can affect individual of all age group. The worst part is, it accumulates unwanted fat in your body thus making you appear fatter and fatter by every passing day.
      Treating hypothyroid as soon as is detected is very important. However, hypothyroid is a disease which makes you intake medicines all through your life. Sometimes injections and surgeries are also accompanied with the medications. Moreover taking medicines for a long time can cause several other health issues like irritation, overweight, kidney damage etc.`
  },
  {
    title: "Thyroid (Hypo/Hyper) Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Thyroid (Hypo/Hyper) Treatment"),
    description: `Hypothyroidism and hyperthyroidism are conditions that affect the function of the thyroid gland, a small gland in the neck that produces hormones that regulate metabolism.

      Treatment for hypothyroidism involves taking daily thyroid hormone replacement medication, typically in the form of levothyroxine, to restore normal thyroid hormone levels. The dose of medication may need to be adjusted over time based on blood test results and symptoms.
      
      Treatment for hyperthyroidism depends on the underlying cause and severity of symptoms. Antithyroid medications such as methimazole or propylthiouracil may be used to block the production of thyroid hormones. Radioactive iodine therapy may also be used to destroy thyroid cells that are producing too much hormone. In some cases, surgery to remove part or all of the thyroid gland may be recommended.`
  },
  {
    title: "Vertiga Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Vertiga Treatment"),
    description: `Vertigo is a condition characterized by a sensation of spinning or dizziness. Treatment for vertigo depends on the underlying cause and severity of symptoms.

      For mild cases of vertigo, rest and simple exercises to promote balance and stability may be helpful in reducing symptoms. This may include the Epley maneuver, a specific head movement designed to reposition inner ear crystals that can cause vertigo.
      
      Medications such as anti-nausea drugs, antihistamines, or sedatives may be used to alleviate symptoms of vertigo. In some cases, physical therapy may be recommended to improve balance and reduce the risk of falls.
      
      If vertigo is caused by an underlying condition, such as an ear infection or Meniere's disease, treating the underlying condition may be necessary to alleviate symptoms of vertigo. In rare cases, surgery may be required to address structural issues in`
  },
  {
    title: "Writer's cramp Treatment",
    url:
      "https://i.natgeofe.com/n/3150335c-18d6-417c-9265-0d1a37d1f488/GettyImages-1170673640_square.jpg",
    routeTitle: getRouteTitle("Writer's cramp Treatment"),
    description: `Writer's cramp is a type of dystonia that affects the muscles of the hand and forearm, making it difficult to perform fine motor tasks such as writing. Treatment for writer's cramp is aimed at reducing symptoms and improving function.

      For mild writer's cramp, rest and gentle stretching exercises may be helpful in relieving symptoms. Occupational therapy may also be recommended to improve hand and wrist strength and flexibility.
      
      In some cases, medications such as botulinum toxin injections may be used to temporarily paralyze the affected muscles and reduce spasms. Surgery to remove or reroute nerves or tendons may also be an option in severe cases.
      
      `
  }
];

export const featuredCourses = [
  {
    id: 1,
    title: "Diploma Course (1 year)",
    routeTitle: getRouteTitle("Diploma Course (1 year)"),
    description: "10 + 2 for Neurotherapist",
    url:
      "https://resources.reed.co.uk/courses/coursemedia/343869/88a3ce6c-fc25-4a00-ab49-ba4742f2f02f_cover.jpg"
  },
  {
    id: 2,
    title: "Basic Course (6 months)",
    routeTitle: getRouteTitle("Basic Course (6 months)"),
    description: "10th pass for Therapist",
    url:
      "https://resources.reed.co.uk/courses/coursemedia/343869/88a3ce6c-fc25-4a00-ab49-ba4742f2f02f_cover.jpg"
  }
];
