import React from 'react'
import { getRouteTitle } from '../../Helpers/commonFn'
import ys from "../../Assets/Images/ys.png";
import { Link } from 'react-router-dom';
import "./courseStyle.css"

const CourseCard = ({item,handler}) => {
  let photo = item?.thumbnail?.url ? item?.thumbnail?.url : ys
  return (<>
    <div className='row'>
  <div class="card-wrapper course-card">
    <div class="wrapper" style={item?.thumbnail?.url ? {backgroundImage : `url(${photo})`,backgroundRepeat : "no-repeat"} : {backgroundSize : "150px",backgroundRepeat : "no-repeat",backgroundImage : `url(${photo})`,backgroundPositionY : "center"}}>
      <div class="date">
        <span class="price">{item?.gist?.price} ₹</span>
      </div>
      <div class="data">
        <div class="content">
          <span class="author">{item?.gist?.author || "Author"}</span>
          <h1 class="title"><Link>{item?.gist?.title || "Course Title"}</Link></h1>
          <p class="text">{item?.gist?.lead || "Course Glimpse"}</p>
        </div>
        <Link onClick={() => handler(getRouteTitle(item?.gist?.title))}><label class="menu-button">BUY</label></Link>
      </div>
    </div>
  </div>
  </div>
    </>)
}

export default CourseCard