import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { getAllExtraTreatments, getTreatmentDetails } from '../../Helpers/commonFn'
import CardsBanner from '../../Components/CardsBanner'
import { treatments } from '../../Helpers/dataHelper'
import InfoTabBanner from '../../Components/InfoTabBanner'

const TreatmentSection = () => {
    const {treatment} = useParams()
    const [treatmentDetails,setTreatmentDetails] = useState({})
    const [similar,setSimilar] = useState([])
    const handleTreatments = async () => {
      // let allTreatments = await getAllExtraTreatments()
      // let details = getTreatmentDetails(treatment,allTreatments)
    //  setTreatmentDetails(details[0])
     let similarTreatments = treatments.filter((item) => item?.routeTitle !== treatment)
     setSimilar(similarTreatments)
    }
    useEffect(()=> {
      handleTreatments()
    },[])
  return (<>
    <div className='treatment-container'>
    <div className='treatment-heading'>
    <FontAwesomeIcon icon={faNotesMedical} /><h1>{treatmentDetails?.title}</h1>
    </div>
    <div className='treatment-description'>
    <InfoTabBanner info={treatmentDetails} isReadMoreEnable={false} />
    </div>
    <h1>Similar Treatments</h1>
    <CardsBanner items={similar} />
    </div>
    </>)
}

export default TreatmentSection