import React from 'react'
import { galleryData, videoData } from '../../Helpers/dataHelper'
import "./style.css"

const GalleryFlow = () => {
    return (<>
        <section class="section">
            <h1>Photo Gallery</h1>
            <div class="grid">
            {galleryData?.map((item) => {
                return <div className={`item ${item?.type}`} style={{backgroundImage : `url(${item?.url})`}}>
                    <div class="item__details">
                    </div>
                    </div>})}
            </div>
        </section>
        <section class="section">
        <h1>Video Gallery</h1>
        <div class="video-grid">
        {videoData?.map((item) => {
            return <div dangerouslySetInnerHTML={{__html: item}} ></div>})}
        </div>
    </section>
    </>)
}

export default GalleryFlow