import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import "./style.css"
import Logo from  "../../Assets/Images/ys.png"

const NavBar = ({ menuItems }) => {
    const [isBurgerActive, setIsBurgerActive] = useState(false)
    const headerRef = useRef(null)
    const handleNavbar = () => {
        if (window.pageYOffset > 50) {
            headerRef.current.classList.add("fix-animation")
        } else {
            headerRef.current.classList.remove("fix-animation")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleNavbar)
        return () => { window.removeEventListener('scroll', handleNavbar) }
    }, [])
    return (<>
        <header className="header">
            <div className="container" ref={headerRef}>
                <nav className="navbar">
                    <Link to="/" className="brand"><img src={Logo} alt="Neurotherapy" /></Link>
                    <div className="burger" onClick={() => setIsBurgerActive(!isBurgerActive)} id="burger">
                        <span className="burger-line"></span>
                        <span className="burger-line"></span>
                        <span className="burger-line"></span>
                    </div>
                    <span className={`overlay ${isBurgerActive ? "is-active" : ""}`} onClick={() => setIsBurgerActive(!isBurgerActive)}></span>
                    <div className={`menu ${isBurgerActive ? "is-active" : ""}`} id="menu">
                        {menuItems.length > 0 && menuItems.map((item) => {
                            return <ul className="menu-inner">
                                <li className="menu-item"><Link className="menu-link" to={item?.title?.split(" ")[0]}>{item?.title}</Link>
                                </li>
                            </ul>
                        })}
                    </div>
                    <div onClick={()=> window.open("https://lms.neurotherapycourse.org/login", "_blank")} className="menu-block">Admission</div>
                </nav>
            </div>
        </header>
    </>)
}

export default NavBar