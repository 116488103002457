import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import AdmissionForm from '../../Components/AdmissionForm'
import "./style.css"
import { getAllExtraCourses, getRouteTitle } from '../../Helpers/commonFn'
import DetailCard from "./DetailCard"
import InfoTabBanner from '../../Components/InfoTabBanner'

const Course = () => {
  const { course } = useParams()
  const [courseDetails, setCourseDetails] = useState({})
  const [coursesData, setCoursesData] = useState([])

  const handleCourses = async () => {
    let courses = await getAllExtraCourses()
    courses = courses?.reverse()
    setCoursesData(courses)
    // debugger
  }
  useEffect(() => {
    handleCourses()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    let details = coursesData.filter(((item) => getRouteTitle(item?.gist?.title) === course))
    setCourseDetails(details[0])
  }, [coursesData])
  return (<>
    <div className="course-detail-container">
      <div className='details-wrapper'>
      <InfoTabBanner info={{title : courseDetails?.gist?.title, description : courseDetails?.gist?.lead}} isTeam={false} detailCard={{isDetailCard : true,data : courseDetails}} />
        <div className='Courses-container'>
          <div className='course-heading'>
            <h3>Overview</h3>
          </div>
          <div className='course-detail'>
            <div className={`course-section description h-c`}>
              <h5>Description</h5>
              <p dangerouslySetInnerHTML={{ __html: (courseDetails?.gist?.Description || courseDetails?.gist?.description) }}></p>
            </div>
            <div className='course-section learn'>
              <h5>What you'll learn</h5>
              <ul>
                {courseDetails?.gist?.learn.map((elem) => {
                  return <li>{elem}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default Course