import React from 'react'
import { getRouteTitle } from '../../Helpers/commonFn'
import ys from "../../Assets/Images/ys.png";

const Card = ({item,handler}) => {
  let profilePhoto = item?.thumbnail?.url ? item?.thumbnail?.url : ys
  return (<>
    <div className='column-wrapper' onClick={() => handler && handler(item?.routeTitle || getRouteTitle(item?.title))}>
    <div className="card">
    <div className='card-img'>
  <img src={(item?.url || profilePhoto)} alt="Avatar" style={ item?.thumbnail?.url ? {padding : "unset", objectFit : "cover"} : {}} />
  </div>
  <div className="card-container">
    <h4><b>{item?.title || (item?.firstName + " " + item?.lastName)}</b></h4>
    <p>{(item?.position || item?.registeredAs)}</p>
  </div>
</div>
</div>
    </>)
}

export default Card