import React from 'react'
import "./style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical,faClipboardQuestion,faGraduationCap,faUserDoctor,faAmbulance,faImage } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const IntroBanner = () => {
    let services = [{title : "Treatments",icon : faNotesMedical,path : "/Treatments"},
    {title : "Courses",icon : faGraduationCap, path :"/Courses"},
    {title : "Neurotherapists",icon : faUserDoctor,path : "/About"},
    {title : "Special Assistance",icon : faClipboardQuestion, path : "/Patient"},
    {title : "Gallery",icon : faImage , path : "/Gallery"},
    {title : "Admissions",icon : faAmbulance, path : "/Courses"}]
  return (<>
    <div className='intro-banner'>
    <div className='info'>
    <h1>Welcome to YS Neurotherapy Health and Research Foundation</h1>
    <p>Neurotherapy is an ancient Indian rehabilitative blood circulation therapy based on the vedic principles & philosophy. This natural healing therapy deals with nerves, muscles, joints and blood & lymphatic channels. Authorities in the field believe that the body is co-ordinated by three different humors or energy forces as Vata (air) pitta (bile) and kapha(water). Neurotherapy helps in restoring and balancing the energy forces of the body that regulates the physiological equilibrium to perform better function of the body mind spirit.</p>
    <p>Neurotherapy has always been used in ayurvedic medicine as a rehabilitative treatment known by different names. Neurotherapy was re-pioneered by Dr. Lajpatrai Mehra in Mumbai, India. The term Neurotherapy (neuro means nadi or nerves; therapy is application), was coined in 1950’s. Neurotherapy, places chief emphasis upon the organism’s integrity of the body’s mechanism as being the most important factor in the maintenance of health..</p>
    <div className='common-btn'><a href='#contact'>Need Help</a></div>
    </div>
    <div className='services'>
    {services.map((service) => {
        return <Link to={service.path}><div className='service'>
        <div className='icon'><FontAwesomeIcon icon={service.icon} /></div>
        <div className='s-name'>{service.title}</div>
        </div></Link>
    })}
    </div>
    </div>
    </>)
}

export default IntroBanner