import React from 'react'
import InfoTabBanner from '../../Components/InfoTabBanner'
import { patientPage } from '../../Helpers/dataHelper'

const Patient = () => {
  return (<>
    <InfoTabBanner info={patientPage} />
    </>)
}

export default Patient