import React from 'react'
import AboutDoctor from '../../Components/AboutTopic'
// import Carousel from '../../Components/Carousel'
import Contact from '../../Components/Contact'
// import Gallery from '../../Components/Gallery'
import InfoTabBanner from '../../Components/InfoTabBanner'
import IntroBanner from '../../Components/IntoBanner'
import Query from '../../Components/Query'
import TopicCards from '../../Components/TopicCards'
import { infoTabBanner1 } from '../../Helpers/dataHelper'
// import { sanjayYadav, CarouselImages, CarouselImages2, infoTabBanner1, infoTabBanner2 } from '../../Helpers/dataHelper'
import Courses from '../Courses'
import Services from '../Services'

const Home = () => {
  return (<>
    <IntroBanner />
    <InfoTabBanner info={infoTabBanner1} isTeam={true} />
    <Services />
    <Query />
    <Courses isFeatured={true} />
    <Contact />
    </>)
}

export default Home