import React from 'react'
import "./style.css"

const Notice = () => {
  return (<>
    <marquee>If you want to organize any Workshop or Camps related to Neurotherapy in your area please Contact Senior Neurotherapist Sanjay Yadav :- +91 9899740763, +91 8368301154</marquee>
    </>)
}

export default Notice
