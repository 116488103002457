import React from 'react'

const Form = ({events}) => {
  return (<>
    <div className='appointment-wrapper admission-wrapper' >
    <form className="form">
  <h2>REGISTER</h2>
  <p type="Name:"><input placeholder="Write your Name here.."></input></p>
  <p type="Email:"><input placeholder="Let us know how to contact you back.."></input></p>
  <p type="Aadhar No:"><input placeholder="Write your Aadhar name here.."></input></p>
  <p type="Phone:"><input placeholder="Enter your Phone no."></input></p>
  <p type="Address:"><input placeholder="Write here.."></input></p>
  <p type="Select Time:">
  <select name="time" id="time">
  {events.length > 0 && events[0]?.time?.map((item) => {
     return <option value={`${item}`}>{item}</option>
  })}
</select></p>
  <div className='dg-details'>
    <div className="two-cols">
      <label>
        DOB
        <input type="date" name="Appointment request" required />
      </label>
    </div>
    <div className='gender'>
  <p type="Male:"><input type="radio" name="Gender" value="Male" /></p>
  <p type="Female:"><input type="radio" name="Gender" value="Female" /></p>
    </div>
    </div>
  <button>Submit</button>
  <div className='extra-contact-info'>
    <span className="fa fa-phone"></span>+91 9899740763, +91 8368301154
    <span style={{marginLeft: "1.2rem"}} className="fa fa-envelope-o"></span> <a href='mailto:support@neurotherapycourse.com'>support@neurotherapycourse.com</a>
  </div>
</form>
</div>
    </>)
}

export default Form