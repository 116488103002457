import React from 'react'
import "./style.css"
import { Link } from 'react-router-dom'

const InfoTable = ({events, isApplyNow}) => {

  return (<>
    <table class="table-container">
	<thead>
		<tr>
			<th><h1>Date</h1></th>
			<th><h1>Time</h1></th>
			<th><h1>Event Title</h1></th>
			<th><h1>Fee</h1></th>
			<th><h1>Place</h1></th>
			{isApplyNow && <th><h1>Registration</h1></th>}
		</tr>
	</thead>
	<tbody>
    {events.length > 0 && events?.map((item) => {
        return <tr>
            <td>{item?.date}</td>
            <td>{item?.time}</td>
            <td>{item?.eventName}</td>
            <td>{item?.fee}</td>
            <td>{item?.place}</td>
            {/* {isApplyNow && <td><button><Link to={item?.routeTitle}>{item?.registration}</Link></button></td>} */}
            <td>{item?.registration}</td>
        </tr>
    })}
    {events.length === 0 ? <h1 className='no-data'>No Scheduled Events</h1> : <></>}
	</tbody>
</table>
    </>)
}

export default InfoTable