import Home from "./Modules/Home";
import { Routes, Route, Red } from "react-router-dom"
import Navbar from "./Modules/Navbar";
import About from "./Modules/AboutUs";
import Footer from "./Components/Footer";
import Notice from "./Components/Notice/index"
import Treatments from "./Modules/Treatments";
import TreatmentSection from "./Modules/Treatments/TreatmentSection";
import Courses from "./Modules/Courses";
import Course from "./Modules/Courses/Course";
import Special from "./Modules/Special";
import UpcommingEvents from "./Modules/UpcommingEvents";
import RegistrationForm from "./Components/RegistratioForm";
import Patient from "./Modules/Patient";
import GalleryModule from "./Modules/GalleryModule";
import Books from "./Modules/Books";
import ErrorPage from "./Components/ErrorPage";
import DoctorInfo from "./Components/DoctorInfo";
function App() {
  // const getRoute = (route) => {
  //   if (route === "Home")return <Home />
  //   else if(route === "About Us") return <About />
  // }
  return (<>
    <Notice />
    <Navbar />
    <div className="app-container">
      <div className="left-sections">
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="Home" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="About/:doctorId" element={<DoctorInfo />} />
          <Route path="Treatments" element={<Treatments />} />
          <Route path="Treatments/:treatment" element={<TreatmentSection />} />
          <Route path="Courses" element={<Courses />} />
          <Route path="Courses/:course" element={<Course />} />
          <Route path="Special" element={<Special />} />
          <Route path="Events" element={<UpcommingEvents />} />
          <Route path="Upcomming/:eventname" element={<RegistrationForm />} />
          <Route path="Patient" element={<Patient />} />
          <Route path="PatientCare" element={<Patient />} />
          <Route path="Gallery" element={<GalleryModule />} />
          <Route path="Books" element={<Books />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </div>
    <Footer />
  </>)
}

export default App;
