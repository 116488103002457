import React from 'react'
import "./style.css"

const Map = () => {
    return (<>
        <div className="location">
        <div className='heading'>
        </div>
        <div class="mapouter"><div class="gmap_canvas"><iframe width="550" height="300" id="gmap_canvas" title="Neurotherapy Center" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d347.42125438963103!2d77.09336621595887!3d28.636139304565642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d05b2f6995047%3A0x9130519e74893e1f!2sAarogya%20Pathcare%20Neurotherapy%20Treatment%20%26%20Training%20Centre!5e0!3m2!1sen!2sin!4v1681564718637!5m2!1sen!2sin" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a></div></div>
        </div></>)
}

export default Map