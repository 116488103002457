import React from 'react'
import Map from '../Map'
import "./style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faLocationDot,faMessage , faPhone } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  return (<>
  <div id='contact' className='contact-wrapper'>
  <div className='heading-wrapper'>
  <h4>CONTACT</h4>
  </div>
    <h1>CONTACT US</h1>
    <div className='contact-details'>
    <div className='maps'>
    <Map />
    </div>
    <div className='hard-details'>
    <div className='detail'>
      <div className='round'><FontAwesomeIcon icon={faLocationDot} /></div>
      <div>
        <h4>Location</h4>
        <p>1/4, 1st Floor, Single Storey, Main Najafgarh Road, Tilak Nagar, New Delhi, Delhi 110018</p>
      </div>
    </div>
    <div className='detail'>
      <div className='round'><FontAwesomeIcon icon={faClock} /></div>
      <div>
        <h4>Clinic Timings</h4>
        <p>10:00 a.m. - 6:00 p.m.</p>
      </div>
    </div>
    <div className='detail'>
    <div className='round'><FontAwesomeIcon icon={faMessage} /></div>
    <div>
    <a href={'mailto:support@neurotherapycourse.com'}>
    <h4>Email</h4>
    <p>support@neurotherapycourse.com</p>
    </a>
    </div>
    </div>
    <div className='detail'>
      <div className='round'><FontAwesomeIcon icon={faPhone} /></div>
      <a href='https://wa.me/919899740763' target='_blank'>
      <div>
        <h4>WhatsApp Number</h4>
        <p>+91 9899740763</p>
      </div>
      </a>
    </div>
    </div>
    </div>
  </div>
  </>)
}

export default Contact