import React from 'react'
import { Link } from 'react-router-dom'
import DetailCard from '../../Modules/Courses/DetailCard'
import "./style.css"

const InfoTabBanner = ({info,isReadMoreEnable,isTeam, detailCard}) => {
  return (<>
    <div className='showcase-wrapper'>
    <div id="showcase">
    <div class="showcase-container">
      <div class="row">
          <div class="showcase-right">
          <div>
          <div className='heading-wrapper'>
          <h4>ABOUT</h4>
          </div>
            <h1>{info?.title}</h1>
            <p>{isReadMoreEnable ? info?.description?.substring(0,500) + "..." : info?.description}</p>
            </div>
          {detailCard?.isDetailCard && <DetailCard selectedCourse={detailCard?.data} />}
            {isReadMoreEnable && <button>Read More</button>}
            {isTeam && <Link to={"/About"}><div className='common-btn'>Meet Our Team</div></Link>}
          </div>
      </div>
    </div>
  </div>
  </div>
    </>)
}

export default InfoTabBanner