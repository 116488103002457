import axios from "axios"
import { baseUrl } from "../helper"


export const getRouteTitle = (title) => {
let titleSplitPresent = title.split(" ")
if (titleSplitPresent) {
    let titleSplit = title.split(" ")
    let titleRoute = titleSplit.join("")
    return titleRoute
}else {
    return title
}
}
export const getAllExtraCourses = async () => {
    let apiUrl = `${baseUrl}/content/course`
    let courses = await axios.get(apiUrl)
    return courses?.data?.items
  }

export const getTreatmentDetails = (treatment, treatments) => {
    let details = treatments?.filter((item) => {
        if (item?.routeTitle === treatment) {return item}
        else if (!item?.routeTitle) {
            if (getRouteTitle(item?.gist?.title) === treatment) {return item}
        }
    })
    return details ? details : {title : "No Data Found"}
}

// getAllTreatments()