import React from 'react'
import NavBar from '../../Components/NavBar'
import { menuItems } from '../../Helpers/dataHelper'

const Navbar = () => {
    return (<>
        <NavBar menuItems={menuItems} />
    </>)
}

export default Navbar