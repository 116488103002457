import React, { useEffect, useState } from 'react'
import { treatments } from '../../Helpers/dataHelper'
import "./style.css"
import { Link } from 'react-router-dom'
import {getRouteTitle} from "../../Helpers/commonFn"
import axios from 'axios'
import { baseUrl } from '../../helper'

const TreatmentGrid = () => {
  const [createdTreatments,setCreatedTreatments] = useState([])

  const getAllTreatments = async () => {
    let {data} = await axios.get(baseUrl + '/content/treatment')
    let treatments = data?.items
    setCreatedTreatments(treatments)
  }

  useEffect(() => {
    getAllTreatments()
  },[])
  return (<>
    <div className='treatment-container'>
    <div className='treatment-heading'><h1>Treatments</h1>
    </div>
    <div className='treatment-grid'>
    {treatments?.map((item)=> {
      return <Link to={`/treatments/${getRouteTitle((item?.title))}`}><div className='treatment-item'>
      <h5>{item?.title}</h5>
      </div></Link>
    })}
    {createdTreatments?.map((item)=> {
      return <Link to={`/treatments/${getRouteTitle((item?.gist?.title || ''))}`}><div className='treatment-item'>
      <h5>{item?.gist?.title }</h5>
      </div></Link>
    })}
    </div>
    {/* <div className="treatment-cards">
    {treatments?.map((item)=> {
        return <Link to={`/treatments/${getRouteTitle(item?.title)}`}><Card item={item} /></Link>
      })}
      </div> */}
    </div>
    </>)
}

export default TreatmentGrid