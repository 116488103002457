import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../TopicCards/Card'
import {getRouteTitle} from "../../Helpers/commonFn"
import DoctorCards from '../TopicCards/DoctorCards'
import CourseCard from '../TopicCards/CourseCard'

const CardsBanner = ({items,handler,isBooks}) => {
  return (<>

    <div className="treatment-cards">
    {isBooks && items?.map((item)=> {
      return <Card item={item} handler={handler} />
     })}
    {!isBooks && items?.map((item)=> {
     return <Link to={`/treatments/${getRouteTitle(item?.title)}`}><Card item={item} handler={handler} /></Link>
    })}
    </div>
    </>)
}

export default CardsBanner