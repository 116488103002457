import React from 'react'
import GalleryCarousel from "../../Components/GalleryCarousel/index"
import GalleryFlow from '../../Components/GalleryFlow'

const GalleryModule = () => {
  return (<>
    <GalleryFlow />
    </>)
}

export default GalleryModule