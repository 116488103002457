import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AboutDoctor from '../../Components/AboutTopic'
import Card from '../../Components/TopicCards/Card'
import DoctorCards from '../../Components/TopicCards/DoctorCards'
import { allDoctors } from '../../Helpers/dataHelper'
import {baseUrl} from "../../helper"
import "./style.css"

const About = () => {
  const {doctorName} = useParams()
  const [selectedDoctor,setSelectedDoctor] = useState(allDoctors[0])
  const [doctorsList,setDoctorsList] = useState([])
  const getAllDoctors = async () => {
    let endpoint = `${baseUrl}/users/registered/neurotherapist`
    let res = await axios.get(endpoint)
    let doctors = res.data
    setDoctorsList(doctors)
  }
  useEffect(() => {
    // if (doctorName) {
    //  let currentDoctor = allDoctors.filter((item) => item?.routeTitle === doctorName)
    //  setSelectedDoctor(currentDoctor[0])
    // }
    getAllDoctors()
  },[])
  return (<>
    <AboutDoctor doctorDetails={selectedDoctor} isReadMoreEnable={false} />
    <h1 className='team-title'>Team</h1>
    <div className='all-doctors'>
    {doctorsList?.map((doctor)=> {
      return <div className='list-doctors'>
      <DoctorCards item={doctor} />
      </div>
    })}
    </div>
    </>)
}

export default About