import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CardsBanner from '../../Components/CardsBanner'
import { booksData } from '../../Helpers/dataHelper'
import {baseUrl} from "../../helper"
import "./style.css"

const Books = () => {
  const [bookRoute,setBookRoute] = useState(false)
  const [books,setBooks] = useState([])
  const handleRoute = (route,e) => {
    if (e) e.stopPropagation()
    setBookRoute(!bookRoute)
    let html = document.getElementsByTagName("html")
    if (!bookRoute) {
     let html = document.getElementsByTagName("html")
     html[0].style.overflowY = "hidden"
    }else {
      html[0].style.overflowY = "unset"
    }
  }
  const getBooks = async () => {
    let res = await axios.get(`${baseUrl}/content/books`)
    let books = res?.data?.items
    setBooks(books)
  }
  useEffect(()=> {
    getBooks()
  },[])
  return (<>
    <div className='books-wrapper'>
    <CardsBanner items={books} handler={handleRoute} isBooks={true} />
    {bookRoute && <div onClick={(e) => handleRoute(null,e)} className='book-container'><embed src={booksData[0].book} width="800px" height="600px" /></div> }
    </div>
    </>)
}

export default Books