import React, { useEffect, useState } from 'react'
import CardsBanner from '../../Components/CardsBanner'
import "./style.css"
import { Link } from 'react-router-dom'
import { getAllExtraCourses } from '../../Helpers/commonFn'
import CourseCard from '../../Components/TopicCards/CourseCard'

const Courses = ({ isFeatured }) => {
  const [coursesData, setCoursesData] = useState([])

  const handleRoute = (route) => {
    window.location.href = "/Courses/" + route
  }
  const handleCourses = async () => {
    let courses = await getAllExtraCourses()
    courses = courses?.reverse()
    setCoursesData(courses)
    // debugger
  }
  useEffect(() => {
    handleCourses()
  }, [])
  return (<>
    <div className='Courses-container'>
      <div className='heading-wrapper'>
        <h4>Courses</h4>
      </div>
      <h1>{isFeatured ? 'Featured' : 'All'}</h1>
      <div className='courses-cards'>
      {(isFeatured ? coursesData.length > 3 ? coursesData.splice(0,3) : coursesData : coursesData)?.map((item)=> {
        return <CourseCard item={item} handler={handleRoute} />
       })}
       </div>
    </div>
  </>)
}

export default Courses