import React from 'react'
import InfoTable from '../../Components/InfoTable'
import { events } from '../../Helpers/dataHelper'

const UpcommingEvents = () => {
  return (<>
    <InfoTable events={events} isApplyNow={true} />
    </>)
}

export default UpcommingEvents