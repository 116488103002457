import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { events } from '../../Helpers/dataHelper'
import InfoTable from '../InfoTable'
import Form from './Form'
import "./index.css"

const RegistrationForm = () => {
    const {eventname} = useParams()
    const [selectedEvent,setSelectedEvent] = useState([])
    useEffect(() => {
        let eventDetails = events.filter((item) => item?.routeTitle === eventname)
        setSelectedEvent(eventDetails)
    },[])
  return (<>
    <InfoTable events={selectedEvent} isApplyNow={false} />
    <Form events={selectedEvent} />
    </>)
}

export default RegistrationForm