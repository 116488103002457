import React from 'react'
import "./style.css"
import ys from "../../Assets/Images/ys.png";

const AboutDoctor = ({doctorDetails,isReadMoreEnable}) => {
  let profilePhoto = doctorDetails?.photo ? doctorDetails?.photo : ys
  return (<>
    <div className='topic-wrapper'>
    <div className='right-about-section'>
    <div class="doctor-description">
    <div className='doctor-meta-details'>
    <img src={doctorDetails?.url || profilePhoto} alt="Neurotherapy" />
    <div>
    <h1>{doctorDetails?.title || (doctorDetails?.firstName + " " + doctorDetails?.lastName)}</h1>
    <p>{doctorDetails?.position || doctorDetails?.registeredAs}</p>
    </div>
    </div>
    <p>{isReadMoreEnable? doctorDetails?.description?.substring(0,1000) + "..." : doctorDetails?.description}</p>
    {isReadMoreEnable ? <button onClick={() => window.location.href = "/About/" + (doctorDetails?.routeTitle || doctorDetails?.firstName)}>Read More</button> : <></>}
  </div>
    </div>
    </div>
    </>)
}

export default AboutDoctor